<template>
  <div>
    <el-card v-if="patientVisit.prescription.length">
      <div class="mb-2" v-for="(item) in patientVisit.prescription" :key="item.id">
        <div @click="viewDetail(item)" class="mb-1 flex">
          <div class="inline-block" role="button">
            <div
              class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
            >
              <span class="mr-1">{{ item.name }}</span>
            </div>
            <!-- <div class="text-xs">
              <span class="mr-1">
                Tạo bởi
                <b>{{ item.doctor && item.doctor.User && item.doctor.User.name }}</b> vào
                <b>{{ formatDateTime(item.created_at) }}</b>
                - {{ patientVisit.clinic && patientVisit.clinic.name }}
                - {{ patientVisit.consulting_room && patientVisit.consulting_room.name }}
              </span>
            </div>-->
            <div class="text-xs italic">
              <span class="mr-1">Cập nhật lần cuối:</span>
              <span>{{ getSignAt(item)}}</span>
            </div>
          </div>
          <div class="ml-auto" :set="status = getApptStatus(item.sign)">
            <div :class="status.class" class="border rounded-full">
              <div class="py-1 px-2">{{ status.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-else>
      <p class="text-center mb-0">Không có dữ liệu.</p>
    </el-card>
    <ModalAddPrescription ref="ModalAddPrescription" @refresh="refreshAndOpenPreview" />
    <ModalViewPrescription
      ref="ModalViewPrescription"
      :patient-visit="patientVisit"
      @refresh="getPersonMedicine"
      :disease="disease"
    />
  </div>
</template>
<script>
import ModalAddPrescription from './Prescription/ModalAdd.vue'
import ModalViewPrescription from './Prescription/ModalView.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'Prescription',
  components: {
    ModalAddPrescription,
    ModalViewPrescription
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    },
    disease: {
      type: Object
    }
  },
  data () {
    return {
      appUtils
    }
  },
  methods: {
    getApptStatus (stt) {
      if (stt) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    refreshAndOpenPreview (idPrescription) {
      this.$emit('refresh')
      this.$refs.ModalViewPrescription.openDialog(idPrescription)
    },
    createPrescription () {
      this.$refs.ModalAddPrescription.openDialog(null, this.patientVisit)
    },
    getPersonMedicine () {
      this.$emit('refresh')
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm')
    },
    viewDetail (prescription) {
      this.$refs.ModalViewPrescription.openDialog(prescription.id)
    },
    formatDateTime (time) {
      return window
        .moment(time)
        .utc()
        .format('HH:mm:ss DD/MM/YYYY')
    },
    getSignAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window
          .moment(item.updated_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      }

      return time
      // return appUtils.formatDateTimeFull(time)
    }
  }
}
</script>
