<template>
  <div class="rounded-lg p-3 shadow-2xl bg-white mb-2">
    <div class="flex items-end">
      <div class="w-2/3 mr-auto">
        <div class="fs-20 fw-500 ml-1 mb-2 text-black">{{ $t('schedule_page.lbl_create_onlineroom') }}</div>
        <div class="flex ml-1">
          <div class="mr-2">{{ $t('schedule_page.lbl_msg_addition_online') }}</div>
        </div>
      </div>
      <div @click="">
        <div class="cursor-pointer px-2 py-2 rounded-lg border border-primary flex items-center bg-primary">
          <img class="ml-2" style="width: 2rem;" src="@public/assets/images/icon/patientVisit/video.svg" alt="">
          <span class="ml-2 text-white">
            {{ $t('schedule_page.lbl_create_onlineroom') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndicationTemp'
  // props: {
  //   name: {
  //     type: String,
  //     required: true
  //   }
  // }
}
</script>