<template>
  <el-dialog fullscreen :visible.sync="dialogVisible" :before-close="handleClose" append-to-body>
    <div class="container">
      <div
        class="text-center text-2xl font-semibold mb-3"
      >{{isCreate ? 'Thêm mới Đơn thuốc' : 'Chỉnh sửa thông tin Đơn thuốc'}}</div>
      <div v-if="prescription.documents && prescription.documents.length">
        <div class="font-bold">File đã đính kèm</div>
        <div class="flex flex-wrap gap-2">
          <div v-for="doc in prescription.documents" :key="doc.id">
            <div class="static w-40">
              <div class="flex flex-col" :title="doc.name">
                <a target="_blank" :href="getLinkDownload(doc.link)" class="no-underline">
                  <img
                    v-if="isImage(doc.name)"
                    :src="getLinkDownload(doc.link)"
                    :alt="doc.name"
                    class="cursor-pointer bg-black w-40 h-40 object-contain"
                  />
                  <img
                    v-else
                    src="../../../../public/assets/images/icon/dowload-file.svg"
                    :alt="doc.name"
                    class="cursor-pointer bg-black w-40 h-40 object-contain"
                  />
                </a>
                <div class="text-sm font-medium truncate">{{doc.name}}</div>
                <div class="text-sm font-medium truncate">{{formatDateDMY(doc.created_at)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-form
        v-if="prescription"
        :model="prescription"
        ref="formRef"
        label-width="120px"
        label-position="top"
      >
        <div class>
          <div class="row">
            <el-form-item
              class="mb-2 col-md-5"
              label="Tên đơn thuốc"
              prop="name"
              :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' }]"
            >
              <el-input v-model="prescription.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="mb-2 col-md-3" label="Ngày kê đơn" prop="date">
              <el-date-picker class="w-full" v-model="prescription.date" type="date"></el-date-picker>
            </el-form-item>
            <el-form-item class="mb-2 col-md-3" label="Bác sĩ kê đơn" prop="prescribed_doctor">
              <el-input v-model="prescription.prescribed_doctor" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item class="mb-2 col-md-5" label="Cơ sở Y Tế">
              <el-input v-model="prescription.prescribed_clinic" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="mb-2 col-md-6" label="Mô tả/Ghi chú">
              <el-input v-model="prescription.description" autocomplete="off"></el-input>
            </el-form-item>
          </div>
        </div>

        <div v-for="(medicine, index) in prescription.person_medicines" :key="index + 'medicine'">
          <div v-if="!medicine.deleted" class="flex mt-3 justify-between">
            <div class="w-3/5">
              <div class="border border-1 p-3 border-indigo-300">
                <div class="flex justify-between">
                  <span class="text-black text-xl">Thông tin thuốc</span>
                  <div>
                    <input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="medicine.is_monitor"
                      autocomplete="off"
                      true-value="1"
                      false-value="0"
                      id="is_monitor"
                    />
                    <label
                      class="text-gray-500 text-base select-none cursor-pointer"
                    >Theo dõi tuân thủ thuốc</label>
                  </div>
                </div>
                <!-- Rule -->
                <el-form-item
                  label="Tên thuốc / liều lượng"
                  :prop="'person_medicines.' + index + '.name'"
                  :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' }]"
                >
                  <!-- <el-input
                    v-model="medicine.name"
                    :disabled="checkStartTime(medicine.status)"
                    autocomplete="off"
                  />-->
                  <v-select
                    :disabled="checkStartTime(medicine.status)"
                    class="select-box"
                    :options="persons || []"
                    label="Product_Name"
                    v-model="medicine.name"
                    placeholder="Chọn thuốc"
                    :filterable="false"
                    @search="searchPerson"
                    @input="selectedPerson()"
                    @open="onOpenPerson"
                    @close="onClosePerson"
                  >
                    <div slot="no-options">
                      <span v-if="!loading_persons">Không tìm thấy tên thuốc!</span>
                    </div>
                    <template v-slot:option="option">
                      <span class="font-weight-bold">{{ option.Product_Name }}</span>
                    </template>
                    <template #list-footer>
                      <li class="text-center mt-1">
                        <span
                          class="text-secondary spinner-border spinner-border-sm mr-2"
                          role="status"
                          v-if="loading_persons"
                        ></span>
                      </li>
                      <li ref="person_list" v-show="hasNextPagePerson"></li>
                    </template>
                  </v-select>
                </el-form-item>
                <div class="flex justify-between gap-4 mt-2">
                  <el-form-item
                    label="ĐVT"
                    :prop="'person_medicines.' + index + '.amount_unit'"
                    :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' }]"
                  >
                    <div class="d-flex">
                      <el-input
                        v-model="medicine.amount_unit"
                        @input="calcTotal(medicine)"
                        :disabled="checkStartTime(medicine.status)"
                        autocomplete="off"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item
                    class="mb-0"
                    label="Lần / ngày"
                    :prop="'person_medicines.' + index + '.freq_per_day'"
                    :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' },
                      { type: 'number', message: 'Trường này chỉ chấp nhận số'}]"
                  >
                    <div class="d-flex">
                      <el-input
                        v-model.number="medicine.freq_per_day"
                        @input="calcTotal(medicine)"
                        :disabled="checkStartTime(medicine.status)"
                        autocomplete="off"
                      >
                        <template slot="append">Lần</template>
                      </el-input>
                    </div>
                  </el-form-item>
                  <el-form-item
                    class="mb-0"
                    label="Mỗi lần sử dụng"
                    :prop="'person_medicines.' + index + '.amount_per_one'"
                    :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' }]"
                  >
                    <div class="el-input el-input-group el-input-group--append">
                      <el-input-number
                        class="text-left"
                        v-model.number="medicine.amount_per_one"
                        :disabled="checkStartTime(medicine.status)"
                        @change="calcTotal(medicine)"
                        :controls="false"
                      ></el-input-number>
                      <div class="el-input-group__append h-8 leading-none">Đơn vị</div>
                    </div>
                  </el-form-item>
                </div>
                <div class="mt-2">
                  <span class="text-gray-500 text-base">Hướng dẫn sử dụng thuốc</span>
                  <el-input
                    v-model="medicine.doctor_note"
                    :disabled="checkStartTime(medicine.status)"
                    autocomplete="off"
                  ></el-input>
                </div>
                <div class="mt-2">
                  <span class="text-gray-500 text-base">Tác dụng phụ</span>
                  <el-input
                    v-model="medicine.side_effect"
                    :disabled="checkStartTime(medicine.status)"
                    autocomplete="off"
                  ></el-input>
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-4">
              <div class="p-3 border w-96">
                <div class="text-black text-xl">Lịch trình uống thuốc</div>
                <el-radio-group v-model="medicine.gap_type">
                  <el-radio
                    class="mt-2"
                    :label="1"
                    :disabled="checkStartTime(medicine.status)"
                  >Hàng ngày</el-radio>
                  <div class="flex items-center my-2 h-8">
                    <el-radio :label="2" :disabled="checkStartTime(medicine.status)">Ngắt quãng:</el-radio>

                    <el-form-item
                      v-if="medicine.gap_type === 2"
                      class="mb-0"
                      :prop="'person_medicines.' + index + '.gap_day'"
                      :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' },
                        { type: 'number', message: 'Trường này chỉ chấp nhận số'}]"
                    >
                      <el-input
                        v-model.number="medicine.gap_day"
                        :disabled="checkStartTime(medicine.status)"
                      >
                        <template slot="append">ngày/lần</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </el-radio-group>
              </div>
              <div class="p-3 border">
                <span class="text-black text-xl">Tính toán</span>
                <el-form-item
                  class="mb-2"
                  label="Tổng số"
                  :prop="'person_medicines.' + index + '.amount'"
                  :rules="[{ required: true, message: 'Trường này không được để trống', trigger: 'blur' }]"
                >
                  <el-input-number
                    class="text-left"
                    v-model.number="medicine.amount"
                    :disabled="checkStartTime(medicine.status)"
                    @change="calcGapDay(medicine)"
                    :controls="false"
                  ></el-input-number>
                </el-form-item>
                <el-form-item class="mb-2" label="Số ngày sử dụng">
                  <el-input
                    v-model="medicine.total"
                    :disabled="checkStartTime(medicine.status)"
                    @input="calcTotal(medicine)"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <el-button
                class="mt-2 text-base"
                icon="el-icon-delete"
                type="danger"
                :disabled="checkStartTime(medicine.status)"
                @click="onMedicineDelete(index)"
              ></el-button>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <el-button
            class="border-0 text-blue-800 text-base"
            @click="onMedicineAdd()"
          >+ Thêm loại khác</el-button>
        </div>
      </el-form>
      <div class="flex justify-end">
        <el-button class="border-0 text-base" @click="handleClose">Huỷ bỏ</el-button>
        <el-button class="text-white w-20 text-sm" type="primary" @click="onSubmit">Lưu</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import DocumentSelect from '../../../components/Diseases/DocumentSelect.vue'
import _ from 'lodash'
import moment from 'moment'
import appUtils from '../../../utils/appUtils'
import vSelect from 'vue-select'

const dataDefault = {
  person_id: '',
  name: '',
  person_diseases_id: '',
  amount_unit: '',
  doctor_note: '',
  person_note: '',
  freq_per_day: 1,
  amount_per_one: 1,
  is_monitor: 1,
  amount: 1,
  side_effect: '',
  error: false,
  gap_type: 1,
  status: 0,
  total: 1
}
export default {
  name: 'ModalAddPrescription',
  components: { DocumentSelect, vSelect },
  data () {
    return {
      //
      persons: null,
      selected_person: null,
      loading_persons: false,

      //
      dialogVisible: false,
      prescription: {
        name: '',
        date: moment(),
        prescribed_doctor: this.$user?.name,
        prescribed_clinic: this.$globalClinic?.name,
        person_medicines: [dataDefault]
      },
      isCreate: false,
      selectedDocs: [],
      selectedMarkDone: [],
      patientVisit: {}
    }
  },
  created () {
    this.getPharmas(20)
  },
  mounted () {
    this.getPharmas(20)
    this.observerPerson = new IntersectionObserver(this.infiniteScrollPerson)
  },
  computed: {
    getParamsID () {
      return this.$route?.params?.id
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) return

      this.getPharmas(20)
    }
  },
  methods: {
    async infiniteScrollPerson ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.$nextTick()
        ul.scrollTop = scrollTop
        if (!self.loading_persons) {
          this.getDoctorPersons((this.persons?.count || 0) + 20)
        }
      }
    },
    hasNextPagePerson () {
      let self = this
      return self.persons && self.persons.total > self.persons.count
    },
    selectedPerson () {
      let self = this
      self.getPharmas()
    },
    onClosePerson () {
      this.observerPerson.disconnect()
    },
    async onOpenPerson () {
      if (this.hasNextPagePerson) {
        await this.$nextTick()
        this.observerPerson.observe(this.$refs.person_list)
      }
    },
    searchPerson (search) {
      this.search_person = search
      this.getPharmas()
    },
    async getPharmas (value) {
      const params = {
        limit: value,
        type: 3
      }
      if (this.search_person) {
        params.Name = this.search_person
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .getPharmas(params)
        .then(res => {
          if (res?.data) {
            this.persons = res.data?.data
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    handleClose () {
      this.dialogVisible = false
      this.$nextTick(() => this.cancel())
    },
    openDialog (obj, currentPatientVisit) {
      this.patientVisit = currentPatientVisit
      this.dialogVisible = true
      this.$nextTick(() => {
        if (obj) {
          this.prescription = _.cloneDeep(obj)
          this.isCreate = false
          if (this.prescription?.person_medicines) {
            for (const item of this.prescription.person_medicines) {
              item.gap_type = item.gap_day > 1 ? 2 : 1
              item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) - 1
              this.calcGapDay(item)
            }
          }
        } else {
          this.isCreate = true
          this.prescription = {
            name: 'Đơn thuốc ' + this.formatDate(moment()),
            date: moment(),
            prescribed_doctor: this.$user?.name,
            prescribed_clinic: this.$globalClinic?.name,
            person_medicines: [dataDefault]
          }
        }
        if (!obj?.person_medicines?.length) {
          this.prescription.person_medicines = [dataDefault]
        }
      })
    },
    cancel () {
      this.prescription = {}
      this.prescription.person_medicines = [dataDefault]
      this.$refs.formRef.resetFields()
      this.selectedDocs = []
      this.selectedMarkDone = []
    },
    calcGapDay (medicine) {
      const result = Math.ceil(
        medicine.amount / (medicine.amount_per_one * medicine.freq_per_day)
      )
      if (!isNaN(result)) {
        medicine.total = result
      }
    },
    calcTotal (medicine) {
      const result =
        medicine.total * (medicine.amount_per_one * medicine.freq_per_day)
      if (!isNaN(result)) {
        medicine.amount = Number(Number.parseFloat(result).toFixed(2))
      }

      if (medicine.freq_per_day) {
        medicine.doctor_note = `Sử dụng ${medicine.freq_per_day} lần/ngày`
        if (medicine.amount_per_one) {
          medicine.doctor_note = `${medicine.doctor_note}, mỗi lần ${
            medicine.amount_per_one
          } đơn vị ${medicine.amount_unit && '(' + medicine.amount_unit + ')'}`
        }
      }
    },
    onMedicineDelete (index) {
      if (this.prescription.person_medicines[index].id) {
        this.$set(this.prescription.person_medicines[index], 'deleted', true)
      } else this.prescription.person_medicines.splice(index, 1)
    },
    onMedicineAdd () {
      const param = {
        person_id: '',
        name: '',
        amount_unit: '',
        doctor_note: '',
        person_note: '',
        freq_per_day: 1,
        amount_per_one: 1,
        is_monitor: 1,
        amount: 1,
        side_effect: '',
        error: false,
        gap_type: 1,
        status: 0,
        total: 1
      }
      this.prescription.person_medicines.push(param)
    },
    onSubmit () {
      this.prescription.person_medicines.forEach((x, index) => {
        const realName = x.name.Product_Name
        this.prescription.person_medicines[index].name = realName
      })
      if (this.isCreate) {
        this.createPrescription()
      } else this.updatePrescription()
    },
    async createPrescription () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        try {
          if (this.prescription?.person_medicines) {
            for (const item of this.prescription.person_medicines) {
              item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) + 1
            }
          }
          const params = {
            person_id: this.patientVisit.person.id,
            patient_visit_id: this.patientVisit.id,
            ...this.prescription,
            document_ids: this.selectedDocs
          }
          if (this.selectedMarkDone.length) {
            params.make_done_document_ids = this.selectedMarkDone
          }

          await this.$rf
            .getRequest('DoctorRequest')
            .createPrescription(params)
            .then(res => {
              if (res?.data) {
                this.$message({
                  message: 'Thêm mới thành công',
                  type: 'success'
                })
                this.handleClose()
                this.$emit('refresh', res.data.id)
              }
            })
        } catch (e) {
          this.$message({
            message: 'Thêm mới thất bại!',
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    async updatePrescription () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        try {
          if (this.prescription?.person_medicines) {
            for (const item of this.prescription.person_medicines) {
              item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) + 1
            }
          }
          const params = {
            person_id: this.patientVisit.person.id,
            patient_visit_id: this.patientVisit.id,
            ...this.prescription,
            document_ids: this.selectedDocs
          }
          if (this.selectedMarkDone.length) {
            params.make_done_document_ids = this.selectedMarkDone
          }

          await this.$rf
            .getRequest('DoctorRequest')
            .updatePrescription(this.prescription.id, params)
            .then(res => {
              if (res?.data) {
                this.$message({
                  message: 'Cập nhật dữ liệu thành công',
                  type: 'success'
                })
                this.handleClose()
                this.$emit('refresh', this.prescription.id)
              }
            })
        } catch (e) {
          this.$message({
            message: 'Cập nhật dữ liệu thất bại!',
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    checkStartTime (status) {
      return status !== 0
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    handlerMarkDone (event) {
      this.selectedMarkDone = event
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
  }
  input {
    text-align: inherit !important;
  }
  .el-input-number {
    width: 100%;
  }
}
.select-box {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f4f4f4;
  border-radius: 5px;
}
</style>
