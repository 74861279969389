<template>
  <div
    class="rounded-lg p-3 shadow-2xl bg-white mb-2 text-black"
    v-loading="isLoading"
  >
    <div class="flex flex-col md:flex-row gap-2 items-center justify-between">
      <h3 class="fs-20 fw-500">{{ $t("Phiếu thu") }}</h3>

      <el-button @click="handleCreateApptReceipt" type="primary" size="small">{{
        $t("Tạo phiếu thu")
      }}</el-button>
    </div>
    <div class="mt-3">
      <div
        v-for="(receipt, index) in receiptList"
        :key="index"
        class="flex flex-col md:flex-row items-center justify-between gap-4 mb-3"
      >
        <div class="flex flex-col md:flex-row gap-2 items-center flex-1">
          <svg
            @click="handleViewDetail(receipt)"
            class="cursor-pointer"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="29"
              height="29"
              rx="3.5"
              stroke="#20409B"
            />
            <mask
              id="mask0_35008_774"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="6"
              y="6"
              width="18"
              height="18"
            >
              <rect
                x="6.92859"
                y="6.92871"
                width="16.1429"
                height="16.1429"
                fill="#D9D9D9"
                stroke="#20409B"
              />
            </mask>
            <g mask="url(#mask0_35008_774)">
              <path
                d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                fill="#20409B"
                stroke="#20409B"
                stroke-width="0.025"
              />
            </g>
          </svg>

          <div
            class="flex-1"
            :set="(paymentStatus = getPaymentStatus(receipt))"
          >
            <div class="flex gap-2">
              <span class="flex-1"> {{ receipt.name }}</span>
              <svg
                v-if="checkEdit(receipt)"
                @click="handleUpdateApptReceipt(receipt)"
                class="cursor-pointer"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  stroke="#20409B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M4 21h16M5.666 13.187A2.278 2.278 0 0 0 5 14.797V18h3.223c.604 0 1.183-.24 1.61-.668l9.5-9.505a2.278 2.278 0 0 0 0-3.22l-.938-.94a2.277 2.277 0 0 0-3.222.001z"
                />
              </svg>
            </div>
            <div :style="`color:${paymentStatus.color}`">
              {{ paymentStatus.label }}
            </div>
          </div>
        </div>
        <div
          style="width: 230px"
          :set="(orderStatus = getOrderStatus(receipt))"
          class="flex flex-col gap-2"
        >
          <div>
            <div :style="`color:${orderStatus.color}`">
              {{ orderStatus.label }}
            </div>
          </div>
          <el-button
            v-if="receipt && receipt.status === 1"
            @click="handleShowModalConfirm(receipt)"
            size="mini"
            plain
            type="primary"
            class="m-0"
          >
            {{ $t("Tạo yêu cầu cung cấp dịch vụ cấp cứu") }}
          </el-button>
          <el-button
            v-if="
              receipt &&
              receipt.service_payment_transaction &&
              receipt.service_payment_transaction.status ===
                TRANSACTION_SLIP_STATUS.unPaid
            "
            class="m-0"
            @click="handlePayment(receipt)"
            size="mini"
            plain
            type="primary"
          >
            {{ $t("Thanh toán") }}
          </el-button>
        </div>
      </div>
    </div>
    <ModalCreateApptReceipt
      ref="ModalCreateApptReceipt"
      @onRefresh="handleGetListApptReceipt"
    />
    <ModalPaymentApptReceipt
      ref="ModalPaymentApptReceipt"
      @onRefresh="handleGetListApptReceipt"
      @onSuccess="handlePaymentSuccess"
    />
    <ModalConfirmCreateOrderEmergency
      ref="ModalConfirmCreateOrderEmergency"
      @onConfirm="handleCreateSupplierOrder"
    />
  </div>
</template>

<script>
import {
  APPOINTMENT_METHOD_STATUS,
  TRANSACTION_SLIP_STATUS
} from '../../utils/constants'
import ModalConfirmCreateOrderEmergency from './ModalConfirmCreateOrderEmergency.vue'
import ModalCreateApptReceipt from './ModalCreateApptReceipt.vue'
import ModalPaymentApptReceipt from './ModalPaymentApptReceipt.vue'

export default {
  components: {
    ModalCreateApptReceipt,
    ModalPaymentApptReceipt,
    ModalConfirmCreateOrderEmergency
  },
  name: 'ApptReceipt',
  props: {
    patientVisit: Object
  },
  data () {
    return {
      isLoading: false,
      receiptList: [],
      TRANSACTION_SLIP_STATUS
    }
  },
  watch: {
    patientVisit: {
      deep: true,
      handler () {
        this.handleGetListApptReceipt()
      }
    }
  },
  created () {
    this.handleGetListApptReceipt()
  },
  methods: {
    async handleGetListApptReceipt () {
      if (!this.patientVisit?.appt_id) return

      try {
        this.isLoading = true
        const params = {
          refer_id: this.patientVisit?.appt_id,
          type: 3, // Appt service
          page_num: 1,
          page_size: 1000
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getServiceTransactionSlip(params)

        this.receiptList = response.data?.data || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getPaymentStatus (data) {
      switch (data?.service_payment_transaction?.status) {
        case TRANSACTION_SLIP_STATUS.paid:
          return {
            label: 'Đã thanh toán',
            color: '#039855'
          }
        case TRANSACTION_SLIP_STATUS.unPaid:
          return {
            label: 'Chưa thanh toán',
            color: '#DE4848'
          }

        default:
          return {
            label: '',
            color: ''
          }
      }
    },
    getOrderStatus (data) {
      switch (data?.status) {
        case 2:
          return {
            label: 'Đã tạo yêu cầu cung cấp dịch vụ',
            color: '#039855'
          }

        default:
          return {
            label: '',
            color: ''
          }
      }
    },
    handleViewDetail (receipt) {
      const hasOnline =
        this.patientVisit.method === APPOINTMENT_METHOD_STATUS.CODE.ONLINE
      if (hasOnline) {
        const routeData = this.$router.resolve({
          name: 'IndicationReceiptDetail',
          params: {
            id: receipt?.id
          },
          query: {
            pv_id: this.patientVisit?.id
          }
        })

        window.open(routeData.href, '_blank')
      } else {
        this.$router
          .push({
            name: 'IndicationReceiptDetail',
            params: {
              id: receipt?.id
            },
            query: {
              pv_id: this.patientVisit?.id
            }
          })
          .catch(() => {})
      }
    },
    handleCreateApptReceipt () {
      this.$refs.ModalCreateApptReceipt.handleOpen(false, this.patientVisit)
    },
    handleUpdateApptReceipt (receiptInfo) {
      this.$refs.ModalCreateApptReceipt.handleOpen(
        true,
        this.patientVisit,
        receiptInfo
      )
    },
    handlePayment (receipt) {
      this.$refs.ModalPaymentApptReceipt.handleOpen(this.patientVisit, receipt)
    },
    async handleCreateSupplierOrder (receipt, payment_method, is_emergency) {
      if (receipt?.status === 2) return

      try {
        this.isLoading = true
        const params = {
          order_date: window.moment().valueOf(),
          org_id: this.$globalClinic?.org_id,
          order_by: this.patientVisit?.doctor?.user_id,
          slip_id: receipt?.id,
          payment_method,
          is_emergency // 1: k cấp cứu,2: cấp cứu
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCreateServiceOrder(params)

        if (response?.status === 200) {
          this.$toast.open({
            message: 'Tạo yêu cầu cung cấp dịch vụ thành công',
            type: 'success'
          })
          this.handleGetListApptReceipt()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    checkEdit (receipt) {
      return (
        receipt.status === 1 &&
        receipt.service_payment_transaction?.status === 1
      )
    },
    handleShowModalConfirm (receipt) {
      this.$refs.ModalConfirmCreateOrderEmergency.handleOpen(receipt)
    },
    async handlePaymentSuccess (receipt) {
      try {
        this.isLoading = true

        await this.handleCreateSupplierOrder(receipt, 1, 1)
        this.handleGetListApptReceipt()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>