<template>
  <div>
    <el-card v-if="indicationOrders.length">
      <div>
        <el-collapse
          class="hodo-el-collapse"
          v-model="activeCollapseItems"
          @change="handleChangeCollapse"
        >
          <el-collapse-item
            :name="`collapse-${index}`"
            v-for="(item, index) in indicationOrders"
            :key="item.id"
          >
            <template slot="title">
              <div
                @click="onHandleViewIndication(item)"
                @click.stop
                class="inline-block"
                role="button"
              >
                <div
                  class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
                >
                  <span class="mr-1">
                    {{ `${$t(item.name)}${item.indication.length ? ` (${item.indication.length})` : ''}`
                    }}
                  </span>
                </div>
                <div class="text-xs italic font-normal mt-1">
                  <span class="mr-1">Cập nhật lần cuối:</span>
                  <span>{{ getUpdatedAt(item) }}</span>
                </div>
              </div>
            </template>
            <div>
              <div
                v-for="(indication, index) in item.indication"
                :key="indication.id"
                :class="index < item.indication.length ? 'border-b border-t-0 border-r-0 border-l-0 border-solid border-gray-200' : ''"
                class="group flex justify-between hover:bg-gray-100 transition-all duration-200 px-2 py-1"
                role="button"
                @click="onHandleViewIndicationResult(indication)"
              >
                <div class="relative transition-all duration-200 w-full pr-2">
                  <span class="txt-pri">{{ $t(indication.name) }}</span>
                </div>
                <div
                  class="italic text-right whitespace-nowrap"
                  :set="status = hasExaminationResults(indication)"
                >
                  <span :class="status.class">{{ status.text }}</span>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <custom-modal
        :showModal="showIndicationModal"
        size="w-full"
        @hideModal="onHideModal"
        :title="indicationOrderDetail ? $t('Chỉnh sửa/ Thay đổi phiếu chỉ định') : $t('Tạo phiếu chỉ định')"
        titleClass="uppercase text-3xl w-full text-center"
        :esc-close="true"
      >
        <CUInficationOrder
          v-if="showIndicationModal"
          :type="type"
          :patient-visit="patientVisit"
          :patient_visit_id="consultationData.patient_visit_id"
          :mr_id="consultationData.medical_record_id"
          :hr_id="consultationData.health_record_id"
          :prop_indication_order="indicationOrderDetail"
          @refresh="onRefresh"
        />
      </custom-modal>

      <CUInficationOrderView
        v-if="showIndicationOrderDetail && indicationOrderDetail"
        :type="type"
        :patient-visit="patientVisit"
        :indicationOrderData="indicationOrderDetail"
        :showIndicationOrderDetail="showIndicationOrderDetail"
        @hideModal="onHideModal"
        @openUpdateIndication="openUpdateIndication"
        @onHandleViewIndicationResult="onHandleViewIndicationResult"
        @reloadPage="onReloadPage"
      />

      <ModalIndicationViewDetail
        v-if="showIndicationDetail"
        :patient-visit="patientVisit"
        :show-indication-detail="showIndicationDetail"
        :indication-detail-data="indicationDetail"
        :type="type"
        @hideModal="onHideModal"
        @reloadPage="onReloadPage"
      />
      <PdfViewer
        ref="PdfViewer"
        containerIDProps="ContainerIndicationPDFViewerID"
        elementIDProps="IndicationPDFViewerID"
      />
    </el-card>
    <el-card v-else>
      <p class="text-center mb-0">Không có dữ liệu.</p>
    </el-card>
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .hodo-el-collapse {
    .el-collapse-item {
      &__header {
        height: auto;
        line-height: normal;
        padding: 8px 0;
      }

      &__wrap {
        border-bottom: none;
      }

      &__content {
        padding-bottom: 8px;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import CustomModal from '../../components/CustomModal'
import appUtils from '../../utils/appUtils'
import { INDICATION_STATUS } from '../../utils/constants'
import CUInficationOrder from '../Indications/V2/CUInficationOrder'
import CUInficationOrderView from '../Indications/V2/CUInficationOrderView'
import ModalIndicationViewDetail from '../../components/PatientVisit/Indication/ModalIndicationViewDetail.vue'

export default {
  name: 'IndicationTemp',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    consultation: {
      type: Object,
      required: true
    },
    patientVisit: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomModal,
    CUInficationOrder,
    CUInficationOrderView,
    ModalIndicationViewDetail
  },
  data () {
    return {
      appUtils,
      showIndicationModal: false,
      showIndicationOrderDetail: false,
      showIndicationDetail: false,
      consultationData: this.consultation || null,
      patientVisitData: this.patientVisit || null,
      indicationOrders: [],
      indicationOrderDetail: null,
      indicationDetail: {},
      INDICATION_STATUS,
      activeCollapseItems: ['collapse-0']
    }
  },
  created () {
    this.consultationData = this.consultation

    const _tempIndicationOrders = _.filter(this.consultationData.indication_order, { cate: this.type })
    this.indicationOrders = _tempIndicationOrders
    this.indicationOrders.map((item, index) => {
      this.activeCollapseItems.push(`collapse-${index}`)
    })
  },
  watch: {
    '$route.query': {
      handler: function () {
        this.handleOpenModalWithRouteQuery()
      },
      deep: true,
      immediate: true
    },
    consultation (data) {
      if (data) {
        this.consultationData = data

        const _tempIndicationOrders = _.filter(this.consultationData.indication_order, { cate: this.type })
        this.indicationOrders = _tempIndicationOrders
        this.indicationOrders.map((item, index) => {
          this.activeCollapseItems.push(`collapse-${index}`)
        })

        if (!this.$route.query) return
        if (this.$route.query?.iorder) {
          const id = this.$route.query?.iorder
          const data = this.indicationOrders.find(item => item.id === Number(id))
          if (!data || typeof data === 'undefined') return
          this.indicationOrderDetail = data
        }
      }
    },
    patientVisit (data) {
      if (data) {
        this.patientVisitData = data
      } else {
        this.patientVisitData = null
      }
    }
  },
  mounted () {
    this.handleOpenModalWithRouteQuery()
  },
  methods: {
    handleOpenModalWithRouteQuery () {
      const self = this
      if (!self.$route.query) return
      if (self.$route.query?.iorder) {
        const id = self.$route.query?.iorder
        const data = self.indicationOrders.find(item => item.id === Number(id))
        if (!data || typeof data === 'undefined') return
        self.onHandleViewIndication(data)
        return
      }

      if (self.$route.query?.indication) {
        const id = self.$route.query?.indication
        let data = null
        // eslint-disable-next-line no-unused-expressions
        self.indicationOrders?.map(order => {
          if (data) return
          data = order?.indication?.find(item => item?.id === Number(id))
        })
        self.onHandleViewIndicationResult(data)
      }
    },
    handleChangeCollapse (val) {
      // console.log(val)
    },
    getIndicationStatus (stt) {
      switch (stt) {
        case INDICATION_STATUS.CODE.PENDING:
          return {
            text: this.$t('Chưa có KQ'),
            class: `text-yellow-600`
          }
        case INDICATION_STATUS.CODE.HAS_RESULT:
          return {
            text: this.$t('Đã có KQ'),
            class: `text-green-700`
          }
        case INDICATION_STATUS.CODE.CS:
          return {
            text: this.$t('Có dấu hiệu bất thường'),
            class: `text-red-600`
          }
        case INDICATION_STATUS.CODE.NCS:
          return {
            text: this.$t('Không có dấu hiệu bất thường'),
            class: `text-blue-800`
          }

        default:
          break
      }
    },
    hasExaminationResults (data) {
      if (data?.examination_result?.id) {
        return {
          text: this.$t('Đã có KQ'),
          class: `text-green-700`
        }
      }
      if (data?.status === 3) {
        return {
          text: this.$t('Đã hủy'),
          class: `text-red-600`
        }
      } else {
        return {
          text: this.$t('Chưa có KQ'),
          class: `text-yellow-600`
        }
      }
    },
    openCreateIndication () {
      this.indicationOrderDetail = null
      this.showIndicationModal = true
    },
    onHideModal (hasQuery, isGoBack) {
      this.showIndicationModal = false
      this.showIndicationOrderDetail = false
      this.showIndicationDetail = false

      if (hasQuery) {
        if (isGoBack) {
          this.$router.go(-1)
        } else {
          this.$router.replace({ query: null })
        }
      }
    },
    onRefresh () {
      this.onHideModal()
      this.$emit('refresh')
    },
    onReloadPage () {
      this.$emit('refresh')

      if (this.$route.query?.iorder) {
        const id = this.$route.query?.iorder
        const data = this.indicationOrders?.find(item => item.id === Number(id))
        this.indicationOrderDetail = data
      }
    },
    onHandleViewIndication (item) {
      if (!item || typeof item === 'undefined') return

      this.indicationOrderDetail = item
      this.showIndicationOrderDetail = true

      if (Number(this.$route.query?.iorder) === Number(item?.id)) return

      this.$router.push({
        query: {
          iorder: item?.id
        }
      })
    },
    openUpdateIndication (item) {
      if (!item || typeof item === 'undefined') return

      this.showIndicationModal = true
      this.indicationOrderDetail = item
    },
    onHandleViewIndicationResult (item) {
      if (!item || typeof item === 'undefined') return

      this.indicationDetail = item
      this.showIndicationDetail = true

      if (Number(this.$route.query?.indication) === Number(item?.id)) return

      this.$router.push({
        query: {
          indication: item?.id
        }
      })
    },
    getUpdatedAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window.moment(item.updated_at).format('HH:mm:ss DD/MM/YYYY')
      }

      return time
    }
  }
}
</script>
