var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"width":"90%","center":"","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"top":"50px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('template',{slot:"title"},[_c('h2',{staticClass:"fs-24 font-bold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.isUpdate ? "Cập nhật Phiếu khám bệnh" : "Viết Phiếu khám bệnh"))+" ")])]),_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('TiptapEditor',{attrs:{"disabled":_vm.patientVisit &&
          _vm.consultationData &&
          _vm.consultationData.id &&
          !!_vm.consultationData.sign,"classProp":"cs-editor","value":_vm.content},on:{"setValue":(t) => (_vm.content = t)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"documentPrint"},[_c('div',{staticClass:"mx-auto hodo-print fs-18",attrs:{"id":"phieukham"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}})])]),(_vm.patientVisit)?_c('div',[_c('b-row',{staticClass:"mt-1"},[_c('div',[_c('div',{staticClass:"modal-footer border-0 justify-content-center flex"},[_c('button',{staticClass:"btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40",attrs:{"disabled":_vm.consultationData && _vm.consultationData.sign,"type":"button"},on:{"click":_vm.modifyConsulation}},[_vm._v(" Lưu ")]),(
                  _vm.isUpdate &&
                  _vm.patientVisit &&
                  _vm.consultationData &&
                  _vm.consultationData.document_link
                )?_c('button',{staticClass:"btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40",attrs:{"disabled":_vm.consultationData && _vm.consultationData.sign,"type":"button"},on:{"click":function($event){return _vm.openModalSignPDF(_vm.consultationData)}}},[_vm._v(" "+_vm._s(_vm.consultationData && _vm.consultationData.sign ? "Đã ký" : "Ký")+" ")]):_vm._e(),(
                  _vm.isUpdate &&
                  _vm.patientVisit &&
                  _vm.consultationData &&
                  _vm.consultationData.document_link
                )?_c('button',{staticClass:"btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40",attrs:{"type":"button"},on:{"click":function($event){return _vm.openDocumentLink(_vm.consultationData)}}},[_vm._v(" In ")]):_vm._e()]),(
                _vm.isUpdate &&
                _vm.consultationData &&
                _vm.consultationData.sign &&
                _vm.consultationData.sign.user
              )?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.consultationData.sign.user.name)+" đã ký "+_vm._s(_vm.getTime(_vm.consultationData.sign.sign_at))+" - "+_vm._s(_vm.formatDateDMY(_vm.consultationData.sign.sign_at))+" ")])]):_vm._e()])])],1):_vm._e()],1)],2),(_vm.patientVisit && _vm.consultationData && _vm.consultationData.id)?_c('div',[_c('PhieuKhamBenhV2',{attrs:{"save_pdf":_vm.save_pdf,"formData":_vm.formDataTemp}})],1):_vm._e(),_c('ModalSignPDF',{ref:"ConsultationSignerPDF",attrs:{"containerIDProps":"ConsultationContainerPDF","elementIDProps":"ConsultationSignerPDF","imageIDProps":"ConsultationImageID","envelopeName":_vm.envelopeName},on:{"onSuccess":_vm.handleSignDocumentSuccess,"onClose":function($event){_vm.dialogVisible = false}}}),_c('PdfViewer',{ref:"ConsultationPdfViewer",attrs:{"containerIDProps":"ContainerConsultationPDFViewerID","elementIDProps":"ConsultationPDFViewerID"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }