<template>
  <div v-if="prescription && dialogVisible && prId">
    <el-dialog fullscreen :modal="false" :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="relative">
        <div v-loading="loading">
          <div class="text-center text-2xl font-semibold mb-3">Chi tiết Đơn Thuốc</div>
          <div class="my-3 text-base">
            <div class="row">
              <div class="col-md-5">
                <div class="font-bold">Tên đơn thuốc</div>
                <div>{{ prescription.name }}</div>
              </div>
              <div class="col-md-4">
                <div class="font-bold">Ngày kê đơn</div>
                <div>{{ formatDate(prescription.date) }}</div>
              </div>
              <div class="col-md-3">
                <div class="font-bold">Bác sĩ kê đơn</div>
                <div>{{ prescription.prescribed_doctor }}</div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-5">
                <div class="font-bold">Cơ sở Y Tế</div>
                <div>{{ prescription.prescribed_clinic }}</div>
              </div>
              <div class="col-md-4">
                <div class="font-bold">Mô tả/Ghi chú</div>
                <div>{{ prescription.description }}</div>
              </div>
              <div class="col-md-3">
                <div class="font-bold">Ngày tạo</div>
                <div>{{ formatDate(prescription.created_at) }}</div>
              </div>
            </div>
          </div>

          <el-table
            class="text-black font-medium text-base w-full"
            :data="prescription.person_medicines"
            border
            :default-sort="{ prop: 'start_date', order: 'descending' }"
          >
            <el-table-column class-name="text-black text-base" label="Tên thuốc">
              <template slot-scope="scope">
                <span class="text-blue-800">{{ scope.row.name }}</span>
                <span class="text-blue-800">
                  {{
                  scope.row.medicine && scope.row.medicine.name
                  }}
                </span>
                <div class="text-sm truncate">
                  <span>Tác dụng phụ: {{ scope.row.side_effect }}</span>
                </div>
                <div class="text-sm truncate">
                  <span>Hướng dẫn: {{ scope.row.doctor_note }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="ĐVT">
              <template slot-scope="scope">
                <span class="text-blue-800">
                  {{
                  scope.row.medicine && scope.row.medicine.amount_unit
                  }}
                </span>
                <span class="text-blue-800">{{ scope.row.amount_unit }}</span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="SL">
              <template slot-scope="scope">
                <span class="text-blue-800">
                  {{
                  formatNumber(scope.row.amount)
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="Lần / ngày">
              <template slot-scope="scope">
                <span class="text-blue-800">{{ scope.row.freq_per_day }} lần</span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="Lượng/lần">
              <template slot-scope="scope">
                <span class="text-blue-800">
                  {{ formatNumber(scope.row.amount_per_one) }}
                  {{ scope.row.medicine && scope.row.medicine.amount_unit }}
                </span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="Lịch trình">
              <template slot-scope="scope">
                <span class="text-blue-800">
                  {{
                  scope.row.gap_day &&
                  (scope.row.gap_day > 1
                  ? "Cách " + (Number(scope.row.gap_day) - 1)
                  : "Hàng")
                  }}
                  ngày
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Ngày bắt đầu"
          sortable
        >
          <template slot-scope="scope">
            <span class="text-blue-800">{{scope.row.start_date ? formatDate(scope.row.start_date) : ''}}</span>
          </template>
            </el-table-column>-->
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Theo dõi"
        >
          <template slot-scope="scope">
            <div><input
                type="checkbox"
                v-model="scope.row.is_monitor"
                @click="changeDrugTracking(scope.row.id,scope.row.is_monitor, $event)"
                style="cursor: pointer;"
              ></div>
          </template>
            </el-table-column>-->
          </el-table>

          <div class="flex mt-3">
            <div class="ml-auto">
              <div class="flex">
                <div>
                  <button
                    type="button"
                    class="btn btn-light mr-1"
                    @click="handleClose"
                    :disabled="loading"
                  >Đóng</button>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn bg-pri text-white mr-1"
                    @click="openLink(documentLink)"
                    :disabled="loading"
                  >
                    <div>Xem / In phiếu</div>
                  </button>
                </div>
              </div>
              <div class="ml-1" v-if="prescription.sign">
                ({{ prescription.sign.user.name }} đã ký - {{ prescription.sign.sign_at
                }})
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.patientVisit && this.prescription">
          <TempPrescription :save_pdf="save_pdf" :formData="formDataTemp" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import appUtils from '../../../utils/appUtils'
import TempPrescription from '../../../templates/DonThuoc/TempPrescription'
import { DOCUMENT_SIGN_TYPES } from '../../../utils/constants'

export default {
  name: 'ModalViewPrescription',
  props: ['patientVisit'],
  components: { TempPrescription },
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      save_pdf: 0,
      documentLink: null
    }
  },
  mounted () {
    this.$root.$once('onSavePDFToS3', this.savePdfToS3)
  },
  computed: {
    formDataTemp () {
      let sign = null

      if (this.prescription?.sign) {
        sign = {
          sign_at: this.prescription?.sign?.sign_at,
          signer_name: this.prescription?.signer_name,
          uuid: this.prescription?.sign?.uuid,
          isDocSign: false
        }
      }

      return {
        person: this.patientVisit?.person,
        prescription: this.prescription,
        consultation: this.patientVisit?.consultation,
        sign
      }
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) {
        let query = Object.assign({}, this.$route.query)
        if (query.id) {
          delete query.id
          this.$router.push({ query }).catch(() => {})
        }
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.prId) {
          query.id = this.prId
          this.$router.push({ query }).catch(() => {})
        }
      }
    },
    patientVisit (data) {
      if (data) {
        if (this.prId) {
          const _tempData = data?.prescription?.find(
            item => item.id === this.prId
          )

          if (!_tempData || typeof _tempData === 'undefined') return

          this.documentLink = _tempData.document_link
        }
      }
    }
  },
  methods: {
    formatNumber (value) {
      return +value
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (id) {
      this.prId = id
      this.dialogVisible = true
      this.$nextTick(() => {
        if (id) {
          this.getPrescriptionByIDData(id)
        }
      })
    },
    cancel () {
      this.prescription = []
    },
    formatDate (date) {
      return date && moment(date).format('DD/MM/YYYY')
    },
    async changeDrugTracking (id, status, e) {
      try {
        if (id) {
          const r = confirm('Bạn có chắc chắn thực hiện thao tác này?')
          if (r) {
            if (appUtils.isDoctor()) {
              await this.$rf
                .getRequest('DoctorRequest')
                .postPersonMedicinesMonitor(id)
                .then(res => {})
            } else if (appUtils.isAdmin()) {
              await this.$rf
                .getRequest('AdminRequest')
                .postPersonMedicinesMonitor(id)
                .then(res => {})
            }
          } else {
            e.target.checked = status
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    editPrescription () {
      this.$refs.ModalAddPrescription.openDialog(
        this.prescription,
        this.patientVisit
      )
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    async getPrescriptionByIDData (id) {
      try {
        this.loading = true

        if (id) {
          const res = await this.$rf
            .getRequest('DoctorRequest')
            .getPrescriptionByID(id)

          if (res?.data) {
            this.prescription = _.cloneDeep(res.data)
            this.loading = false
            this.$emit('refresh')
          }
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Don_Thuoc_${this.prescription?.name}_BN_${this.person?.name}.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        try {
          await html2pdf()
            .from(this.$refs.TemplatePrescription)
            .set(options)
            .save()
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      }, 50)
    },
    async signDocument () {
      if (!this.prescription) return

      if (!confirm(`Phiếu đã ký không thể sửa. Bạn có chắc chắn muốn ký?`)) {
        return
      }

      try {
        let params = {
          id: this.prescription.id,
          type: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
          method: 1
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .signDocument(params)

        if (res?.data) {
          await this.getPrescriptionByIDData(this.prescription.id).then(
            response => {
              this.save_pdf++
            }
          )

          this.$message({
            message: this.$t('Ký xác nhận thành công'),
            type: 'success'
          })
          this.$emit('refresh')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async savePdfToS3 (base64) {
      if (!base64 || !this.prescription?.id) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('Đơn Thuốc')} ${this.prescription?.created_at}`
            )}_` || 'prescription_',
          path: 'prescriptions',
          type: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
          type_id: this.prescription?.id,
          uuid: this.prescription?.uuid
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.loading = false
          this.$emit('refresh')
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    openLink (link) {
      if (!link) return
      link && window.open(link, '_blank', 'noreferrer')
    }
  }
}
</script>

<style lang="scss"></style>
