<template>
  <div>
    <el-dialog center :visible.sync="dialogVisible" :before-close="handleClose">
      <!-- body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <div v-html="contentComputed"></div>
        <div class="modal-footer border-0 justify-content-center flex">
          <button
            v-if="consultationData && consultationData.id"
            @click="openLink(consultationData.document_link)"
            type="button"
            class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40"
          >In</button>
        </div>
      </b-overlay>
    </el-dialog>

    <div v-if="patientVisit && consultationData">
      <PhieuKhamBenhV2 :save_pdf="save_pdf" :formData="formDataTemp" />
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BFormGroup,
  BFormInput,
  BForm,
  BCol
} from 'bootstrap-vue'
import { mixinPrint } from '../../../utils/mixinPrint'
import appUtils from '../../../utils/appUtils'
import { DOCUMENT_SIGN_TYPES } from '../../../utils/constants'
import PhieuKhamBenhV2 from '../../../templates/PhieuKhamBenh/v2/PhieuKhamBenh'

export default {
  mixins: [mixinPrint],
  name: 'ModalViewConsultation',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BOverlay,
    PhieuKhamBenhV2
  },
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      patientVisit: null,
      save_pdf: 0,
      consultationData: {},
      consultation_id: 0
    }
  },
  mounted () {
    this.$root.$once('onSavePDFToS3', this.savePdfToS3)
  },
  computed: {
    contentComputed () {
      // const addString = '<colgroup><col style="width:25%"><col style="width:40%"><col><col></colgroup>'
      // let position = this.consultationData?.content.lastIndexOf('<table>') + '<table>'.length
      // let output = [this.consultationData?.content.slice(0, position), addString, this.consultationData?.content.slice(position)].join('')
      return this.consultationData?.content
    },
    consultationEdit () {
      if (this.consultationData && this.consultationData.id) {
        return {
          reason: this.consultationData?.reason || '',
          progression: this.consultationData?.progression || '',
          diagnose: this.consultationData?.diagnose || '',
          prehistoric: this.consultationData?.prehistoric || ''
        }
      } else {
        return {
          reason: '',
          progression: '',
          diagnose: '',
          prehistoric: ''
        }
      }
    },
    formDataTemp () {
      let sign = null
      if (this.consultationData && this.consultationData?.sign) {
        sign = {
          sign_at: this.consultationData?.sign?.sign_at,
          signer_name: this.consultationData?.signer_name,
          uuid: this.consultationData?.sign?.uuid,
          isDocSign: false
        }
      }

      return {
        person: this.patientVisit?.person,
        consultation: this.consultationData,
        sign
      }
    }
  },
  methods: {
    async getPatientVisit (id) {
      try {
        this.loading = true

        const self = this
        const res = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailPatientVisit(id)

        if (res?.data) {
          self.patientVisit = res?.data
          self.consultationData = res.data?.consultation?.find(
            elm => elm.id === this.consultation_id
          )
        }

        this.loading = false

        return res
      } catch (error) {
        console.log('', error)
        this.loading = false
      }
    },
    openEditConsulation () {
      this.dialogVisible = false
      this.$emit('openModify')
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (object, consultation_id) {
      // this.prId = id
      this.consultation_id = consultation_id
      this.getPatientVisit(object.id)
      this.dialogVisible = true
    },
    getGender (gender = 1) {
      return gender === 1
        ? this.$t('schedule_page.lbl_male')
        : gender === 2
          ? this.$t('schedule_page.lbl_female')
          : gender === 3
            ? this.$t('schedule_page.lbl_other')
            : ''
    },
    async signDocument () {
      if (!this.consultationData.id) return

      let _confirm = null

      if (!this.consultationData?.prehistoric) {
        _confirm = await this.$confirm(
          this.$t('Phiếu khám chưa có kết luận.<br/>Bạn chắc chắn muốn ký?'),
          this.$t('Cảnh báo'),
          {
            dangerouslyUseHTMLString: true
          }
        ).catch(_ => {})

        if (!_confirm) return
      }

      try {
        this.loading = true

        const params = {
          id: this.consultationData.id,
          type: DOCUMENT_SIGN_TYPES.CODE.CONSULTATION,
          method: 1
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .signDocument(params)

        if (res?.data) {
          await this.getPatientVisit(this.patientVisit.id).then(response => {
            this.save_pdf++
          })

          this.$message({
            message: this.$t('Ký xác nhận thành công'),
            type: 'success'
          })
          this.$emit('refresh')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async savePdfToS3 (base64) {
      if (!base64 || !this.consultationData?.id) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('Phiếu khám bệnh')} ${
                this.consultationData?.created_at
              }`
            )}_` || 'consultation_',
          path: 'consultations',
          type: DOCUMENT_SIGN_TYPES.CODE.CONSULTATION,
          type_id: this.consultationData?.id,
          uuid: this.consultationData?.uuid
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.loading = false
          await this.getPatientVisit(this.patientVisit?.id)
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    getPersonInfoString (p) {
      return (
        this.formatDateDMY(p.birthday) +
        ' (' +
        this.getAges(p.birthday) +
        ')' +
        (p.career ? ', ' + p.career : '')
      )
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    cancel () {
      this.prescription = []
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm')
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time)
      // let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    // getAvatar () {
    //   let clinic = this.$globalClinic
    //   if (clinic && clinic.avatar) {
    //     return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + clinic.avatar
    //   }
    // },
    getDateTime3 (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY HH:mm')
    },
    openLink (link) {
      if (!link) return
      link && window.open(link, '_blank', 'noreferrer')
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 5rem;
}
</style>
