<template>
  <el-dialog align-center :visible.sync="dialogVisible" :before-close="handleClose">
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <div class="text-center text-2xl font-semibold">Phiếu khám</div>
      <div v-if="patientVisit">
        <div v-if="patientVisit.consultation" class="text-center text-sl mb-3 italic">Chỉnh sửa lần cuối: {{
          patientVisit.consultation.updated_at }}</div>
        <b-row>
          <b-col>
            <div class="flex mb-3">
              <div class="fw-500 fs-15">Phòng khám:</div>
              <div class="ml-3 fs-15">{{ patientVisit.consulting_room ? patientVisit.consulting_room.name : '' }}</div>
            </div>
            <div class="flex mb-3">
              <div class="fw-500 fs-15">Bệnh nhân:</div>
              <div class="ml-3 fs-15">{{ patientVisit.person ? patientVisit.person.name : '' }}</div>
            </div>
            <div class="flex mb-3">
              <div class="fw-500 fs-15">Thời gian bắt đầu:</div>
              <div class="ml-3 fs-15">
                {{ getTime(patientVisit.start_time) }} -
                {{ formatDateDMY(patientVisit.start_time) }}
              </div>
            </div>
            <b-form>
              <b-row>
                <b-form-group class="mb-2" label="Lý do khám">
                  <EditorInput id="reason-conclusion" :prop_text="consultationEdit.reason"
                    @onChangeContent="(content) => consultationEdit.reason = content" />
                </b-form-group>
                <b-form-group class="mb-2" label="Diễn biến">
                  <EditorInput id="progression-conclusion" :prop_text="consultationEdit.progression"
                    @onChangeContent="(content) => consultationEdit.progression = content" />
                </b-form-group>
                <b-form-group class="mb-2" label="Chẩn đoán">
                  <EditorInput id="diagnose-conclusion" :prop_text="consultationEdit.diagnose"
                    @onChangeContent="(content) => consultationEdit.diagnose = content" />
                </b-form-group>
                <b-form-group class="mb-2" label="Kết luận">
                  <EditorInput id="prehistoric-conclusion" :prop_text="consultationEdit.prehistoric"
                    @onChangeContent="(content) => consultationEdit.prehistoric = content" />
                </b-form-group>
              </b-row>
            </b-form>
          </b-col>
          <div class="border-0 justify-content-center flex">
            <button @click="modifyConsulation" type="button"
              class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40">Lưu</button>
          </div>
        </b-row>
      </div>
    </b-overlay>
  </el-dialog>
</template>

<script>
import {
  BOverlay,
  BRow,
  BFormGroup,
  BFormInput,
  BForm,
  BCol
} from 'bootstrap-vue'
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'ModalViewConsultation',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BOverlay,
    EditorInput
  },
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      patientVisit: null
    }
  },
  computed: {
    consultationEdit () {
      if (this.patientVisit?.consultation) {
        return {
          reason: this.patientVisit?.consultation?.reason || '',
          progression: this.patientVisit?.consultation?.progression || '',
          diagnose: this.patientVisit?.consultation?.diagnose || '',
          prehistoric: this.patientVisit?.consultation?.prehistoric || ''
        }
      } else {
        return {
          reason: this.patientVisit?.visit_reason,
          progression: '',
          diagnose: '',
          prehistoric: ''
        }
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (object) {
      // this.prId = id
      this.dialogVisible = true
      this.patientVisit = object
    },
    async modifyConsulation () {
      this.loading = true
      const payload = {
        ...this.consultationEdit,
        patient_visit_id: this.patientVisit.id
      }
      if (this.patientVisit.consultation) {
        await this.$rf
          .getRequest('DoctorRequest')
          .updateConsultation(this.patientVisit.consultation.id, payload)
          .then(res => {
            this.loading = false
            this.$emit('refresh')
            this.handleClose()
          })
          .catch(e => {
            this.loading = false
            console.log(e)
          })
      } else {
        await this.$rf
          .getRequest('DoctorRequest')
          .addConsulation(payload)
          .then(res => {
            this.loading = false
            this.$emit('refresh')
            this.handleClose()
          })
          .catch(e => {
            this.loading = false
            console.log(e)
          })
      }
    },
    cancel () {
      this.prescription = []
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm')
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 5rem;
}
</style>
