<template>
  <div>
    <el-dialog
      width="90%"
      center
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      top="50px"
    >
      <template slot="title">
        <h2 class="fs-24 font-bold">
          {{
            $t(isUpdate ? "Cập nhật Phiếu khám bệnh" : "Viết Phiếu khám bệnh")
          }}
        </h2>
      </template>
      <!-- body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <TiptapEditor
          :disabled="
            patientVisit &&
            consultationData &&
            consultationData.id &&
            !!consultationData.sign
          "
          classProp="cs-editor"
          :value="content"
          @setValue="(t) => (content = t)"
        />

        <div v-show="false" ref="documentPrint">
          <div id="phieukham" class="mx-auto hodo-print fs-18">
            <div v-html="content"></div>
          </div>
        </div>
        <div v-if="patientVisit">
          <b-row class="mt-1">
            <div>
              <div class="modal-footer border-0 justify-content-center flex">
                <button
                  :disabled="consultationData && consultationData.sign"
                  @click="modifyConsulation"
                  type="button"
                  class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40"
                >
                  Lưu
                </button>
                <button
                  v-if="
                    isUpdate &&
                    patientVisit &&
                    consultationData &&
                    consultationData.document_link
                  "
                  :disabled="consultationData && consultationData.sign"
                  @click="openModalSignPDF(consultationData)"
                  type="button"
                  class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40"
                >
                  {{
                    consultationData && consultationData.sign ? "Đã ký" : "Ký"
                  }}
                </button>
                <button
                  v-if="
                    isUpdate &&
                    patientVisit &&
                    consultationData &&
                    consultationData.document_link
                  "
                  @click="openDocumentLink(consultationData)"
                  type="button"
                  class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40"
                >
                  In
                </button>
              </div>
              <div
                v-if="
                  isUpdate &&
                  consultationData &&
                  consultationData.sign &&
                  consultationData.sign.user
                "
                class="text-center"
              >
                <span>
                  {{ consultationData.sign.user.name }} đã ký
                  {{ getTime(consultationData.sign.sign_at) }} -
                  {{ formatDateDMY(consultationData.sign.sign_at) }}
                </span>
              </div>
            </div>
          </b-row>
        </div>
      </b-overlay>
    </el-dialog>

    <div v-if="patientVisit && consultationData && consultationData.id">
      <PhieuKhamBenhV2 :save_pdf="save_pdf" :formData="formDataTemp" />
    </div>
    <ModalSignPDF
      containerIDProps="ConsultationContainerPDF"
      elementIDProps="ConsultationSignerPDF"
      imageIDProps="ConsultationImageID"
      ref="ConsultationSignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
      @onClose="dialogVisible = false"
    />
    <PdfViewer
      ref="ConsultationPdfViewer"
      containerIDProps="ContainerConsultationPDFViewerID"
      elementIDProps="ConsultationPDFViewerID"
    />
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BFormGroup,
  BFormInput,
  BForm,
  BCol
} from 'bootstrap-vue'
import { mixinPrint } from '../../../utils/mixinPrint'
import appUtils from '../../../utils/appUtils'
import {
  DOCUMENT_SIGN_TYPES,
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  SIGNATORY_ROLE
} from '../../../utils/constants'
import PhieuKhamBenhV2 from '../../../templates/PhieuKhamBenh/v2/PhieuKhamBenh'
import TiptapEditor from '@/components/Editors/Tiptap'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import SignatureRequest from '../../../api/request/SignatureRequest'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'

export default {
  mixins: [mixinPrint, mixinGetPersonInfo, mixinSignPdf],
  name: 'ModalViewConsultation',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BOverlay,
    PhieuKhamBenhV2,
    TiptapEditor,
    ModalSignPDF,
    PdfViewer
  },
  data () {
    return {
      clinic: this.$globalClinic || null,
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      patientVisit: null,
      save_pdf: 0,
      consultationData: {},
      DOCUMENT_SIGN_TYPES,
      content: '',
      isUpdate: false,
      diseasesCode: null,
      diseasesList: [],
      patientVisitId: 0,
      consultation_id: null,
      ENVELOPE_NAME_TYPE
    }
  },
  mounted () {},
  watch: {
    async isUpdate (value) {
      if (value === true) {
        await this.getPatientVisit(this.patientVisitId)
      }
    }
  },
  computed: {
    consultationEdit () {
      if (this.consultationData?.id) {
        return {
          reason: this.consultationData?.reason || '',
          progression: this.consultationData?.progression || '',
          diagnose: this.consultationData?.diagnose || '',
          prehistoric: this.consultationData?.prehistoric || '',
          prehistoric_family: this.consultationData?.prehistoric_family || '',
          examine: this.consultationData?.examine || '',
          examine_parts: this.consultationData?.examine_parts || '',
          conclusion: this.consultationData?.conclusion || '',
          treatment: this.consultationData?.treatment || '',
          solution: this.consultationData?.solution || '',
          handling_direction: this.consultationData?.prehistoric || ''
        }
      } else {
        return {
          reason: '',
          progression: '',
          diagnose: '',
          prehistoric: '',
          prehistoric_family: '',
          examine: '',
          examine_parts: '',
          conclusion: '',
          treatment: '',
          solution: '',
          handling_direction: ''
        }
      }
    },
    formDataTemp () {
      let sign = null
      if (this.consultationData?.sign) {
        sign = {
          sign_at: this.consultationData?.sign?.sign_at,
          signer_name: this.consultationData?.signer_name,
          uuid: this.consultationData?.sign?.uuid,
          isDocSign: false
        }
      }

      return {
        person: this.patientVisit?.person,
        consultation: this.consultationData,
        sign
      }
    },
    envelopeName () {
      return this.handleGetEnvelopeName(
        ENVELOPE_NAME_TYPE.patientVisit,
        this.patientVisit
      )
    }
  },
  methods: {
    async getPatientVisit (id) {
      try {
        this.loading = true

        const self = this
        const res = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailPatientVisit(id)

        if (res?.data) {
          self.patientVisit = res?.data
        }
        self.consultationData =
          res.data?.array_consultation?.find(
            (elm) => elm.id === this.consultation_id
          ) || {}
        if (self.consultationData?.content) {
          this.content = self.consultationData?.content
        } else {
          this.handleCreateContent()
        }
        this.loading = false

        return res
      } catch (error) {
        console.log('', error)
        this.loading = false
      }
    },
    openEditConsulation () {
      this.dialogVisible = false
      this.$emit('openModify')
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    async openDialog (object, consultation_id) {
      this.consultation_id = consultation_id
      this.patientVisitId = object.id
      if (consultation_id) this.isUpdate = true
      else this.isUpdate = false
      if (this.isUpdate) {
        await this.getPatientVisit(object.id)
      } else {
        this.patientVisit = object
        this.handleCreateContent()
      }

      // this.prId = id
      this.dialogVisible = true
    },
    async signDocument () {
      if (!this.consultationData?.id) return

      // let _confirm = null

      // if (!this.consultationData?.prehistoric) {
      //   _confirm = await this.$confirm(
      //     this.$t('Phiếu khám chưa có kết luận.<br/>Bạn chắc chắn muốn ký?'),
      //     this.$t('Cảnh báo'),
      //     {
      //       dangerouslyUseHTMLString: true
      //     }
      //   ).catch((_) => {})

      //   if (!_confirm) return
      // }

      try {
        this.loading = true
        const params = {
          id: this.consultationData.id,
          type: DOCUMENT_SIGN_TYPES.CODE.CONSULTATION,
          method: 1
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .signDocument(params)

        if (res?.data) {
          // await this.getPatientVisit(this.patientVisit.id).then((response) => {
          //   this.save_pdf++
          // })

          // this.$message({
          //   message: this.$t('Ký xác nhận thành công'),
          //   type: 'success'
          // })
          this.dialogVisible = false

          // this.onPrintMe(false, this.handleSignDocument)
          this.$emit('refresh')
          // this.$refs.
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      } finally {
        this.loading = false
      }
    },

    getPersonInfoString (p) {
      return (
        this.formatDateDMY(p.birthday) +
        ' (' +
        this.getAges(p.birthday) +
        ')' +
        (p.career ? ', ' + p.career : '')
      )
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    cancel () {
      this.prescription = []
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getTime (date_time) {
      return window.moment(date_time).utc().format('HH:mm:ss')
    },
    formatDateTime (date_time) {
      return window.moment(date_time).utc().format('HH:mm:ss - DD/MM/YYYY')
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time)
      // let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    // getAvatar () {
    //   let clinic = this.$globalClinic
    //   if (clinic && clinic.avatar) {
    //     return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + clinic.avatar
    //   }
    // },
    getGender (gender = 1) {
      return gender === 1
        ? this.$t('schedule_page.lbl_male')
        : gender === 2
          ? this.$t('schedule_page.lbl_female')
          : gender === 3
            ? this.$t('schedule_page.lbl_other')
            : ''
    },
    getDateTime3 (date_time) {
      return (
        date_time && window.moment(date_time).format('DD/MM/YYYY HH:mm:ss')
      )
    },
    openLink (link) {
      if (!link) return
      link && window.open(link, '_blank', 'noreferrer')
    },
    async onPrintMe (openPrintPdf, callback, consultationID) {
      const self = this
      const bodyHTML = self.$refs.documentPrint.innerHTML

      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })

      const base64Data = appUtils.base64.decode(tempHTML)

      await this.savePdfToS3(
        base64Data,
        openPrintPdf,
        callback,
        consultationID
      )
    },
    async savePdfToS3 (base64, openPrintPdf, callback, consultationID) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'Phieukham',
          path: 'consultations',
          type: DOCUMENT_SIGN_TYPES.CODE.CONSULTATION,
          type_id: consultationID || this.consultationData?.id,
          uuid: this.patientVisit?.identifier
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.loading = false

          // eslint-disable-next-line
          callback && callback(res.data?.documentLink);

          if (openPrintPdf) {
            window.open(res.data?.documentLink, '_blank', 'noreferrer')
          }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    replacePlaceholders (template, data) {
      return template.replace(/\$\{(.*?)\}/g, (match, p1) => {
        const key = p1.replace('data.', '').trim()
        return key in data ? data[key] : match
      })
    },
    async handleCreateContent () {
      const data = {
        authorityInCharge: this.clinic?.organization?.authority_in_charge || '',
        orgName: this.clinic?.organization?.name || '',
        clinicName: this.clinic?.name || '',
        personName: this.patientVisit?.person?.name || '',
        personAge:
          this.patientVisit?.person?.age ||
          this.getAges(this.patientVisit?.person?.birthday) ||
          '',
        personGender: this.patientVisit?.person?.gender
          ? this.getGender(this.patientVisit?.person?.gender)
          : '',
        personAddress: this.patientVisit?.person
          ? this.getAddress(this.patientVisit?.person)
          : '',
        phone: this.patientVisit?.person?.phone || '',
        consultingRoom: this.patientVisit?.consulting_room?.name || '',
        startTime: this.patientVisit?.start_time
          ? this.formatDateTime(this.patientVisit?.start_time)
          : '',
        reason:
          this.consultationEdit?.reason ||
          this.patientVisit?.visit_reason ||
          '',
        progression: this.consultationEdit?.progression || '',
        diagnose: this.consultationEdit?.diagnose || '',
        prehistoric: this.consultationEdit?.prehistoric || '',
        prehistoric_family: this.consultationEdit?.prehistoric_family || '',
        examine: this.consultationEdit?.examine || '',
        examine_parts: this.consultationEdit?.examine_parts || '',
        conclusion: this.consultationEdit?.conclusion || '',
        treatment: this.consultationEdit?.treatment || '',
        solution: this.consultationEdit?.solution || '',
        handling_direction: this.consultationEdit?.handling_direction || '',
        doctorName: this.$user?.name || '',
        date: this.formatDateDMY(this.patientVisit?.start_time),
        icd_name: this.patientVisit?.icd_name || ''
      }

      try {
        if (!this.$globalClinic?.org_id) return
        this.loading = true

        const res = await this.getConsultationTemplates()
        if (!res?.data) return
        const rawContent = res.data?.content
        const filledContent = this.replacePlaceholders(rawContent, data)
        setTimeout(() => {
          this.content = filledContent
          this.loading = false
        }, 250)
      } catch (error) {
        console.log(error)
      } finally {
      }
    },
    async modifyConsulation () {
      this.loading = true
      const self = this
      try {
        const payload = {
          ...this.consultationEdit,
          patient_visit_id: this.patientVisit.id,
          content: this.content
        }
        if (this.isUpdate) {
          await this.$rf
            .getRequest('DoctorRequest')
            .updateConsultation(this.consultationData.id, payload)
            .then(async (res) => {
              const psData = await self.getPatientVisit(this.patientVisit.id)
              this.content = psData?.data?.array_consultation?.find(
                (elm) => elm.id === this.consultationData.id
              )?.content
              const consultationRes = psData?.data?.array_consultation?.find(
                (elm) => {
                  return elm?.id === this.consultationData?.id
                }
              )

              this.consultation_id = this.consultationData?.id
              this.consultationData = consultationRes
              await this.onPrintMe(false, null, consultationRes?.id)

              // this.loading = false
              this.$emit('refresh')
              await self.getPatientVisit(this.patientVisit.id)
              const responseData = await this.handleProcessSignEnvelope()
              if (responseData) {
                this.isUpdate = true
                this.$message({
                  message: this.$t('Cập nhật thành công'),
                  type: 'success'
                })
                this.openModalSignPDF()
              }

              // this.consultation_id=

              // this.handleClose()
            })
            .catch((e) => {
              this.loading = false
              console.log(e)
              this.$message({
                message: this.$t('Cập nhật thất bại'),
                type: 'error'
              })
            })
        } else {
          await this.$rf
            .getRequest('DoctorRequest')
            .addConsulation(payload)
            .then(async (res) => {
              this.consultation_id = res?.data?.id

              const psData = await self.getPatientVisit(this.patientVisit.id)
              const consultationRes = psData?.data?.array_consultation?.find(
                (elm) => {
                  return elm?.id === this.consultationData?.id
                }
              )
              this.consultationData = consultationRes

              // this.content =
              //   psData?.data?.consultation[
              //     psData?.data?.consultation.length - 1
              //   ]?.content

              // console.log()

              await this.onPrintMe(false, null, consultationRes?.id)

              this.loading = false
              this.$emit('refresh')
              this.isUpdate = true
              await self.getPatientVisit(this.patientVisit.id)

              const responseData = await this.handleProcessSignEnvelope()

              if (responseData) {
                this.$message({
                  message: this.$t('Tạo mới thành công'),
                  type: 'success'
                })

                this.openModalSignPDF()
              }

              // this.handleClose()
            })
            .catch((e) => {
              this.loading = false
              console.log(e)
              this.$message({
                message: this.$t('Tạo mới thất bại'),
                type: 'error'
              })
            })
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleGetHtmlContent (data) {
      return `<div  dir="ltr" align="left">
        <table style="width:100%">
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr>
                <td colwidth="250" colspan="1">
                    <p dir="ltr"  >
                        <strong>${String(
    data.authorityInCharge
  ).toUpperCase()}</strong>
                    </p>
                    <p dir="ltr"  >
                        <strong>${data.orgName}</strong>
                    </p>
                    <p dir="ltr"  >
                      <strong>${data.clinicName}</strong>
                    </p>
                </td>
                <td colspan="2">
                    <p style="text-align:center" dir="ltr">
                      <strong>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</strong>
                    </p>
                    <p style="text-align:center" dir="ltr">
                       <strong>Độc lập - Tự do - Hạnh phúc</strong>
                    </p>
                    <p style="text-align:center"><strong>------------------------------</strong></p>
                </td>
                <td  colspan="1">
                    <p style="text-align:right;width:100%" dir="ltr">
                        MS:..............
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<h1 style="text-align:center"><strong>PHIẾU KHÁM BỆNH</strong></h1>
<p  dir="ltr">
    <span>Họ tên người bệnh: <strong>${
  data.personName
} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></span>
    <span>Tuổi: ${
  data.personAge
}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span>Nam/Nữ: ${data.personGender}</span>
</p>
<p dir="ltr">
   <span>Địa chỉ: ${
  data.personAddress
} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
   <span>Khoa: ${data.clinicName}</span>
</p>
<p dir="ltr">
    Điện thoại: ${data.phone}
</p>
<p dir="ltr">
    Tiền sử:
</p>
<p  dir="ltr">
    <span>Mạch: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span>Huyết áp: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span>Đường Huyết: </span>
</p>
<br/>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col/>
            <col/>
            <col/>
        </colgroup>
        <tbody>
            <tr>
                <td colspan="1">
                    <br/>
                    <p></p>
                </td>
                <td colspan="2">
                    <br/>
                    <p></p>
                </td>
                <td colspan="1" >
                    <p style="text-align:center"  dir="ltr">
                      Ngày ${data.date}
                    </p>
                    <p dir="ltr" style="text-align:center" >
                        <strong>BÁC SĨ KHÁM BỆNH</strong>
                    </p>
                    </br>
                    <p dir="ltr" style="text-align:center" >
                        ${data.doctorName}
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
    },
    async handleCheckHasEnvelop () {
      const signEnvelope = await this.handleCheckSignEnvelope(
        ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
        this.consultationData?.id,
        'ConsultationPdfViewer',
        'ConsultationSignerPDF'
      )

      return signEnvelope
    },
    async openModalSignPDF () {
      const signEnvelope = await this.handleCheckHasEnvelop()

      if (!signEnvelope?.id) {
        const responseData = await this.handleProcessSignEnvelope()
        if (responseData) {
          await this.handleCheckHasEnvelop()
        }
      }
    },
    async handleSignDocumentSuccess (dataBase64) {
      try {
        const file = appUtils.convertBase64tToFile(
          dataBase64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, this.consultationData?.id)

        await this.$rf
          .getRequest('DoctorRequest')
          .updateConsultationV2(this.consultationData?.id, {
            document_link: url
          })

        await this.signDocument()
      } catch (error) {
        console.log(error)
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `consultation`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    openDocumentLink (consultation) {
      if (!consultation?.document_link) return

      window.open(consultation?.document_link, '_blank')
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async getConsultationTemplates () {
      try {
        this.loading = true
        let response

        response = await this.$rf
          .getRequest('DoctorRequest')
          .getConsultationTemplates({
            orgId: this.$globalClinic?.org_id
          })
        if (!response?.data) return

        if (response.data?.code === 200001) {
          response = await this.$rf
            .getRequest('DoctorRequest')
            .getConsultationTemplates({
              orgId: 0
            })
        }

        return response
      } catch (error) {
        console.log(error)
      }
    },
    async handleProcessSignEnvelope () {
      let responseData
      const procedureSelected = await this.getSignProcedures(
        ENVELOPE_DOCUMENT_TYPE.EXAMINATION
      )
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'doctor_exam') {
            user = this.consultationData?.doctor?.User
          }
          // const user = this.getUserByRole(item?.user_role)

          return {
            ...item,
            user,
            user_id: user?.id,
            node_signatory_id: item?.id,
            signatory_name: user?.name,
            signatory_email: user?.email,
            signatory_role: SIGNATORY_ROLE.signer,
            signatory_order: item?.sign_order,
            role_name: item?.role_name,
            is_required: item?.is_required,
            expired_date: item?.expired_date || undefined
          }
        })

        return arr.concat(signs)
      }, [])

      const signEnvelope = await this.handleGetEnvelopeByDocumentID(
        this.consultationData?.id,
        ENVELOPE_DOCUMENT_TYPE.EXAMINATION
      )

      if (signEnvelope?.id) {
        responseData = await this.handleUpdateSignEnvelope(
          signEnvelope.id,
          signatories,
          this.consultationData?.document_link,
          ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
          this.consultationData?.id,
          this.envelopeName
        )

        return responseData
      } else {
        responseData = await this.handleCreateEnvelope(
          signatories,
          this.consultationData?.document_link,
          procedureSelected?.id,
          ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
          this.consultationData?.id,
          this.envelopeName
        )

        return responseData
      }
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 5rem;
}

.cs-editor {
  color: black !important;

  & * {
    border: none !important;
  }
}
</style>
