<template>
  <div>
    <!-- Body -->
    <el-card class="mb-2" v-if="patientVisit && patientVisit.array_consultation.length">
      <div v-if="patientVisit.array_consultation.length > 0">
        <div
          v-for="item in patientVisit.array_consultation"
          :key="item.id"
          @click="modalPreviewConsulation(item)"
        >
          <div class="mb-2">
            <div class="mb-1 flex">
              <div class="inline-block" role="button">
                <div
                  class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
                >
                  <span class="mr-1">Phiếu khám {{ item.created_at }}</span>
                </div>
                <div class="text-xs">
                  <span class="mr-1">
                    Tạo bởi
                    <b>{{ item.doctor && item.doctor.User && item.doctor.User.name }}</b> vào
                    <b>{{ formatDateTime(item.created_at) }}</b>
                    - {{ patientVisit.clinic && patientVisit.clinic.name }}
                    - {{ patientVisit.consulting_room && patientVisit.consulting_room.name }}
                  </span>
                </div>
                <div class="text-xs italic">
                  <span class="mr-1">{{ item.sign ? 'Đã ký:' : 'Đã lưu:' }}</span>
                  <span>{{getSignAt(item) }}</span>
                </div>
              </div>
              <div v-if="item.sign" class="ml-auto" :set="status = getApptStatus(item.sign)">
                <div :class="status.class" class="border rounded-full">
                  <div class="py-1 px-2">{{ status.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-else>
      <p class="text-center mb-0">Không có dữ liệu.</p>
    </el-card>
    <ModifyConsultationModal ref="ModifyConsultationModal" @refresh="openPreviewAndRefresh" />
    <PreviewConsultationModal
      ref="PreviewConsultationModal"
      @refresh="refresh"
      @openModify="modalUploadConsulationAppt()"
    />
  </div>
</template>
  
  <script>
import { BRow, BFormGroup, BFormInput, BForm, BCol } from 'bootstrap-vue'
import appUtils from '../../utils/appUtils'
import ModifyConsultationModal from './Consultation/ModifyConsultationModal.vue'
import PreviewConsultationModal from './Consultation/PreviewConsultationModal.vue'

export default {
  name: 'AddConsulationTemp',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    ModifyConsultationModal,
    PreviewConsultationModal
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    }
  },
  computed: {
    // isDisableAddSultation () {
    //   if (this.patientVisit) {
    //     if (this.patientVisit.consultation) {
    //       if (this.patientVisit.consultation.sign) {
    //         return false
    //       } else {
    //         return true
    //       }
    //     } else {
    //       return true
    //     }
    //   }
    // }
  },
  data () {
    return {
      isShowModal: false,
      consulation: {},
      appUtils
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    openPreviewAndRefresh () {
      this.modalPreviewConsulation(true)
      this.$emit('refresh')
    },
    getApptStatus (stt) {
      if (stt) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    getSignAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window
          .moment(item.updated_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      }

      // return appUtils.formatDateTimeFull(time)
      return time
    },
    // modal add consulation
    modalUploadConsulationAppt () {
      this.$refs.ModifyConsultationModal.openDialog(this.patientVisit)
    },
    // modal preview consulation
    modalPreviewConsulation (item) {
      // this.$refs.PreviewConsultationModal.openDialog(this.patientVisit, item.id)
      if (item.document_link) window.open(item.document_link)
      // window.$('#modalPreviewConsulation').modal(show ? 'show' : 'hide')
    },
    openEditConsulation () {
      this.$refs.ModifyConsultationModal.openDialog(this.patientVisit)
      this.modalPreviewConsulation(false)
    },
    formatDateTime (time) {
      return window
        .moment(time)
        .utc()
        .format('HH:mm:ss DD/MM/YYYY')
    }
  }
}
</script>
  