<template>
  <div>
    <div class="d-flex align-items-center">
      <p v-if="items && items.from && items.to" class="m-0 text-body">{{ $t('title.display') }} <span>{{  items.from }} đến {{items.to }} trên {{ items ? items.total : 0 }}</span> {{ $t('title.item') }}</p>
      <ul class="pagination m-0 ml-auto">
        <li class="page-item m-1" :class="prevPage <= 0 ? 'disabled' : ''">
          <button v-if="items && current_page > 1" @click="gotoURL(prevPage)" class="page-link" tabindex="-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><polyline points="15 6 9 12 15 18" /></svg>
            {{ $t('button.prev') }}
          </button>
        </li>
        <li class="page-item active m-1"><button @click="gotoURL(current_page)" class="page-link bg-pri">{{ current_page }}</button></li>
        <li class="page-item m-1">
          <button v-if="items && current_page < items.last_page" class="page-link" @click="gotoURL(nextPage)">
            {{ $t('button.next') }} <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><polyline points="9 6 15 12 9 18" /></svg>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: ['items', 'current_page'],
    computed: {
      prevPage () {
        return this.current_page > 1 ? this.current_page - 1 : 0
      },
      nextPage () {
        return (this.items && this.items.last_page < this.current_page + 1) ? this.current_page : this.current_page + 1
      }
    },
    methods: {
      gotoURL (page) {
        let self = this
        // if (self.$router.currentRoute.query.page !== page) {
        //   self.$router.push({name: self.$router.currentRoute.name, query: {page: page}}).catch(() => {})
        // }
        // self.$emit('onRefresh', {page})
        if (self.$router.currentRoute.query === null) {
          // alert('hello')
          if (self.$router.currentRoute.query.page !== page) {
            self.$router.push({name: self.$router.currentRoute.name, query: {page: page}}).catch(() => {})
          }
        } else {
          // console.log(self.$route.fullPath) /doctor/call-management?type=upcoming
          self.$router.push({path: self.$route.fullPath, query: {page: page}}).catch(() => {})
        }
        self.$emit('onRefresh', {page})
      }
    }
  }
</script>
