<template>
  <div v-if="patientVisit && patientVisit.id">
    <!-- Body -->
    <div class="rounded-lg p-3 hover:bg-gray-100 shadow-2xl bg-white mb-2">
      <div class="flex">
        <div class="fs-20 fw-500 ml-1">{{ $t("schedule_page.lbl_exam_pape") }}</div>
        <div
          @click="
            patientVisit.status !== EXAMINATION_STATUS.CODE.CANCELED
              ? modalUploadConsulationAppt()
              : () => {}
          "
          class="ml-auto px-6 py-2 rounded-lg border border-primary flex cursor-pointer disabled"
        >
          <img src="@public/assets/images/icon/patientVisit/plus-circle.svg" alt />
          <p class="text-primary font-weight-bold mb-0 ml-2 text-center">
            {{
            patientVisit.array_consultation && patientVisit.array_consultation.length > 0
            ? "Thêm phiếu"
            : "Viết phiếu"
            }}
          </p>
        </div>
      </div>
      <div
        v-if="patientVisit.array_consultation && patientVisit.array_consultation.length > 0"
        class="mt-2"
      >
        <div v-for="item in patientVisit.array_consultation" :key="item.id">
          <div class="mb-2" @click="modalPreviewConsulation(item)">
            <div class="mb-1 flex">
              <div class="inline-block" role="button">
                <div
                  class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
                >
                  <span class="mr-1">Phiếu khám {{ appUtils.formatDateTimeUTC(item.created_at) }}</span>
                </div>
                <div class="text-xs italic">
                  <span class="mr-1">
                    {{
                    item.sign ? "Đã ký:" : "Đã lưu:"
                    }}
                  </span>
                  <span>{{ getSignAt(item) }}</span>
                </div>
              </div>
              <div v-if="item.sign" class="ml-auto" :set="(status = getApptStatus(item.sign))">
                <div :class="status.class" class="border rounded-full">
                  <div class="py-1 px-2">{{ status.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ModifyConsultationModal ref="ModifyConsultationModal" @refresh="openPreviewAndRefresh" /> -->
    <PreviewConsultationModal
      ref="PreviewConsultationModal"
      @refresh="refresh"
      @openModify="modalUploadConsulationAppt()"
      @onSignSuccess="handleSignSuccess"
    />
    <PdfViewer
      ref="PdfViewer"
      containerIDProps="ContainerConsultationPDFViewerID"
      elementIDProps="ConsultationPDFViewerID"
    />
  </div>
</template>

<script>
import { BCol, BForm, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
import appUtils from '../../utils/appUtils'
// import ModifyConsultationModal from './Consultation/ModifyConsultationModal.vue'
import PreviewConsultationModal from './Consultation/PreviewConsultationModal.vue'
import PdfViewer from '../PdfViewer/PdfViewer.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  EXAMINATION_STATUS
} from '../../utils/constants'

export default {
  name: 'AddConsulationTemp',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    // ModifyConsultationModal,
    PreviewConsultationModal,
    PdfViewer
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    }
  },
  // computed: {
  // },
  data () {
    return {
      isShowModal: false,
      consultation: {},
      appUtils,
      EXAMINATION_STATUS
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    openPreviewAndRefresh () {
      this.modalPreviewConsulation(true)
      this.$emit('refresh')
    },
    getApptStatus (stt) {
      if (stt) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    // modal add consultation
    modalUploadConsulationAppt () {
      this.$refs.PreviewConsultationModal.openDialog(this.patientVisit)
    },
    // modal preview consultation
    modalPreviewConsulation (item) {
      if (item?.sign?.id && item?.document_link) {
        this.$refs.PdfViewer.handleOpen(
          item?.document_link,
          ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
          item.id
        )
        // window.open(item?.document_link, '_blank')
      } else {
        this.$refs.PreviewConsultationModal.openDialog(
          this.patientVisit,
          item.id
        )
      }

      // window.$('#modalPreviewConsulation').modal(show ? 'show' : 'hide')
    },
    getSignAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window
          .moment(item.updated_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      }

      // return appUtils.formatDateTimeFull(time)
      return time
    },
    handleSignSuccess () {
      this.$emit('refresh')
    }
  }
}
</script>
